
import { useStore } from 'vuex';
import { defineComponent, ref,onMounted,computed} from 'vue'
import { useRouter } from 'vue-router';


export default defineComponent({
  components: {
   
  },
  setup(){
    const store = useStore();
    const router = useRouter();

    const fields = ref([
      {
        title: "Date",
        dataIndex: "site_name",
      },
      {
        title: "Site Name",
        dataIndex: "site_name",
      },
      {
        title: "Tank Name",
        dataIndex: "site_name",
      },
      {
        title: "Product Name",
        dataIndex: "site_name",
      },
      {
        title: "Sales",
        dataIndex: "site_name",
      },
      {
        title: "Action",
        dataIndex: "action",
        slots: { customRender: "action" },
        align: 'center'
      },
    ]);

    const index = (payload={page: 1}) => {
      store.dispatch("RetailerSites/index", payload);
    };

    const filterRecord = (payload: any) => {
      index(payload);
    };

    const editRecord = (record:any) => {
      router.push(`/retailer-site-details-form/`+record.id)
    };

    const createRecord = () => {
      router.push('/sales/create')
    };

    const items = computed(() => store.getters["RetailerSites/items"]);
    const loading = computed(() => store.getters["RetailerSites/status"]);

    onMounted(index);


    return {
      loading,
      items,
      fields,
      filterRecord,
      editRecord,
      createRecord
    };
  },
});
